<template>
  <div class="ln-reviews">
    <header class="ln-head ln-head--sm">
      <div class="ln-head__titulo ln-head__titulo--h3">Nuestros usuarios dicen:</div>
    </header>

    <div class="ln-reviews__items">
      <VueSlickCarousel v-bind="reviewsSection">
        <div class="ln-reviews__item">
          <div class="b-card">
            <div class="testimonial-item">
              <div class="testimonial-item__text">
                "Esta aplicación va genial para crear tu lista de nacimiento! Tiene muchas opciones y es muy intuitiva. Muy recomendable!."
              </div>
              <div class="testimonial-item__name">Marta Cintas González</div>
            </div>
          </div>
        </div>
        <div class="ln-reviews__item">
          <div class="b-card">
            <div class="testimonial-item">
              <div class="testimonial-item__text">
                "Muy buena solución para facilitar el tema regalos al nacer un bebé! Es súper fácil de usar y muy útil. Lo recomiendo ❤️."
              </div>
              <div class="testimonial-item__name">Estefanía C.</div>
            </div>
          </div>
        </div>
        <div class="ln-reviews__item">
          <div class="b-card">
            <div class="testimonial-item">
              <div class="testimonial-item__text">
                "App súper útil e intuitiva para hacer la wishlist del bebé. Muy recomendable."
              </div>
              <div class="testimonial-item__name">Ichamar</div>
            </div>
          </div>
        </div>
      </VueSlickCarousel>
    </div>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

export default {
  name: "LandingReviews",
  components: {
    VueSlickCarousel,
  },
  data() {
    return {
      reviewsSection: {
        slidesToShow: 4,
        arrows: false,
        responsive: [
          {
            breakpoint: 9999,
            settings: "unslick",
          },
          {
            breakpoint: 991,
            settings: {
              slidesToShow: 2,
              draggable: true,
              centerPadding: "50px",
              dots: true,
            },
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 1,
              draggable: true,
              dots: true,
            },
          },
        ],
      },
    };
  },
};
</script>

<style lang="scss" scoped></style>
