<template>
  <div class="ln-features__item">
    <div class="ln-features__card">
      <picture class="ln-features__slider-ico">
        <slot name="ico"></slot>
      </picture>
      <div class="ln-features__slider-title">
        <slot name="title"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LandingFeaturesSimpleItem",
};
</script>
