<template>
  <section class="ln-rattings">
    <div class="ln-rattings__items">
      <div class="ln-rattings__item">
        <div class="ln-rattings__logo">
          <img src="../../../assets/img/logos/logo-m-app-store.svg" alt="" />
        </div>
        <div class="ln-rattings__ratting">
          <span>5</span>
          <img src="../../../assets/img/ico/ico-star-fill.svg" alt="" />
        </div>
      </div>
      <div class="ln-rattings__item">
        <div class="ln-rattings__logo">
          <img src="../../../assets/img/logos/logo-m-play-store.svg" alt="" />
        </div>
        <div class="ln-rattings__ratting">
          <span>5</span>
          <img src="../../../assets/img/ico/ico-star-fill.svg" alt="" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "LandingAppRatings",
};
</script>

<style lang="scss" scoped></style>
