<template>
  <footer class="ln-footer">
    <div class="bb-container">
      <div class="ln-footer__container">
        <div class="ln-footer__copy">© helloBB 2024</div>
        <div class="ln-footer__nav">
          <router-link to="/cookies">{{ $t("cookiesPolicy.cookiesPolicy") }}</router-link>
          <router-link to="/politica-privacidad">{{ $t("privacyPolicy.privacyPolicy") }}</router-link>
          <router-link to="/legal">{{ $t("legalNotice.legalNotice") }}</router-link>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "LandingFooter",
};
</script>
