<template>
  <section class="cta-hablan ln-reviews-cta">
    <div class="ln-head ln-head--sm">
      <div class="ln-head__titulo ln-head__titulo--sm">Hablan de nosotros</div>
    </div>

    <div class="cta-hablan__logos">
      <VueSlickCarousel v-bind="hablanSection">
        <div class="cta-hablan__logo">
          <img
            loading="lazy"
            src="../../../assets/img/logos/logo-cta-diari-tarragona.png"
            alt="El diari de Tarragona"
          />
        </div>
        <div class="cta-hablan__logo">
          <img
            loading="lazy"
            src="../../../assets/img/logos/logo-cta-estrella-digital.png"
            alt="Estrella Digital"
          />
        </div>
        <div class="cta-hablan__logo">
          <img
            loading="lazy"
            src="../../../assets/img/logos/logo-cta-elboletin.png"
            alt="El Bloetin"
          />
        </div>

        <div class="cta-hablan__logo">
          <img
            loading="lazy"
            src="../../../assets/img/logos/logo-cta-mundo-financiero.png"
            alt="El Mundo financiero"
          />
        </div>

        <div class="cta-hablan__logo">
          <img
            loading="lazy"
            src="../../../assets/img/logos/logo-cta-siglo.png"
            alt="Diario Siglo XXI"
          />
        </div>
        <div class="cta-hablan__logo">
          <img
            loading="lazy"
            src="../../../assets/img/logos/logo-cta-murcia.png"
            alt="Murcia.com"
          />
        </div>
        <div class="cta-hablan__logo">
          <img
            loading="lazy"
            src="../../../assets/img/logos/logo-cta-expansion-alpha.png"
            alt="Expansion Negocios"
          />
        </div>
      </VueSlickCarousel>
    </div>

    <div class="back-to-top" v-if="showToTop">
      <bbButton
        label="Empezar ahora"
        @click="scrollToTop()"
        class="button--accent button--wide-4"
      />
    </div>
  </section>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

import bbButton from "../../../components/buttons/bbButton.vue";

export default {
  name: "hablanSection",
  components: {
    VueSlickCarousel,
    bbButton,
  },
  props: {
    showToTop: {
      value: Boolean,
      default: false,
    },
  },
  data() {
    return {
      hablanSection: {
        slidesToShow: 4,
        arrows: false,
        responsive: [
          {
            breakpoint: 9999,
            settings: "unslick",
          },
          {
            breakpoint: 767,
            autoplay: true,
            settings: {
              slidesToShow: 3,
              draggable: true,
              dots: false,
            },
          },
        ],
      },
    };
  },
  methods: {
    scrollToTop() {
      //scroll to element with class = scrollto
      const el = document.querySelector(".scrollto");
      el.scrollIntoView({ behavior: "smooth" });
    },
  },
};
</script>
