<template>
  <div class="ln-features">
    <div class="ln-head">
      <div class="ln-head__titulo">{{ title }}</div>
    </div>

    <div class="ln-features__items" v-if="useCarousel">
      <VueSlickCarousel id="vs2" v-bind="featuredSection">
        <slot></slot>
      </VueSlickCarousel>
    </div>

    <div class="ln-features__grid" v-else>
      <slot></slot>
    </div>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

export default {
  name: "LandingFeaturesSimple",
  props: {
    title: String,
    useCarousel: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    VueSlickCarousel,
  },
  data() {
    return {
      featuredSection: {
        slidesToShow: 4,
        arrows: true,
        responsive: [
          {
            breakpoint: 991,
            settings: {
              slidesToShow: 2,
              draggable: true,
              dots: true,
              arrows: false,
            },
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 1,
              draggable: true,
              dots: true,
              arrows: false,
            },
          },
        ],
      },
    };
  },
};
</script>
